import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './index.scss'

const fixAmount = amount => {
  if (
    amount === null ||
    amount === undefined ||
    amount === '' ||
    amount === false
  ) {
    return ''
  }
  return parseFloat(amount).toFixed(2).toString()
}

class AdditionalFields extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subName: '',
      subVal: '',
      isDebtsModalVisible: false,
      additionalDebtFields: [],
      currencyIsActive: false,
      vendorCurrencyIsActive: false,
      debtValue: '',
      isDebtTotal: false,
      vendorAmountValue: '',
      amountValue: '',
      additionalFields: [],
      debtsList: [],
    }
  }

  componentDidMount() {
    this.setState({
      ...this.processCheckData(
        this.props.checkData,
        this.props.service,
        this.props.defaultAmount,
      ),
    })
  }

  processCheckData(json, service, defaultAmount) {
    const additionalFields = []
    const debtsList = []
    let amountValue = defaultAmount || ''
    let fixedAmount = false
    let cantPayWithoutDebt = false
    let debt = ''
    if (!json) {
      return {
        additionalFields,
        debtsList,
        amountValue,
        fixedAmount,
        debt,
        cantPayWithoutDebt,
      }
    }

    if (json.person && json.person !== '') {
      additionalFields.push({
        // localeName: 'person',
        localeName: 'person',
        value: json.person,
      })
      if (json.debt && json.debt !== 0) {
        if (!json.debt.toString().includes('-')) {
          debt = json.debt.toString()
          amountValue = json.debt.toString()
          this.onChange({ amountValue })
        }
        additionalFields.push({
          // localeName: debt,
          localeName: 'debt',
          value: json.debt,
        })
      }
    }
    if (json.values.length) {
      for (const i in json.values) {
        const item = json.values[i]
        const amount = (item.amount / 100).toFixed(2)
        let debt = {
          id: '',
          title: '',
          label: '',
          value: '',
          key: '',
          additionalDebtFields: [],
          fixedAmount: item.fixedAmount,
          cantPayWithoutDebt: item.cantPayWithoutDebt,
        }
        // debt.label = `${amount} ${item.currency} ${item.localeName['valueAz']}`
        debt.label = `${amount} ${item.currency} ${item.localeName[this.props.langKey]
          }`
        debt.value = amount
        debt.key = item.value
        debt.id = item.value
        debt.title = item.title
        debt.additionalDebtFields = item.additionalInfoBasedOnServiceInfoItem
        debtsList.push(debt)
      }
      amountValue = debtsList[0].value || ''
      fixedAmount = debtsList[0].fixedAmount
      cantPayWithoutDebt = debtsList[0].cantPayWithoutDebt
      this.pickDebt(debtsList[0])
    }
    if (service.fixedPayment && !json.values.length) {
      amountValue = fixAmount(json.fixedAmount)
      this.onChange({
        additionalFields: additionalFields,
      })

      if (json.currency !== json.vendorCurrency) {
        amountValue = this.calculateCurrency(
          (amountValue / 100).toFixed(2),
        ).amountValue

        this.setState(
          this.calculateCurrency((json.fixedAmount / 100).toFixed(2)),
        )
        this.onChange(
          this.calculateCurrency((json.fixedAmount / 100).toFixed(2)),
        )
      } else {
        this.onChange({ amountValue })
      }
    } else {
      amountValue = fixAmount(amountValue)
    }
    debt = fixAmount(debt)
    return {
      additionalFields,
      debtsList,
      amountValue,
      fixedAmount,
      debt,
      cantPayWithoutDebt,
      isFirstAmount: amountValue ? true : false,
    }
  }

  pickDebt(debt) {
    const amountValue = fixAmount(debt.value)
    this.setState({
      amountValue,
      subName: debt.title,
      subVal: debt.id,
      additionalDebtFields: debt.additionalDebtFields,
      debtValue: debt.label,
      fixedAmount: debt.fixedAmount,
      cantPayWithoutDebt: debt?.cantPayWithoutDebt,
      isFirstAmount:
        parseFloat(amountValue) || parseFloat(debt?.fixedAmount) ? true : false,
    })
    this.onChange({
      subName: debt?.title,
      subVal: debt?.id,
      additionalDebtFields: debt?.additionalDebtFields,
      amountValue,
    })
  }

  amountInputRender = (
    value,
    isActive,
    isActiveFieldName,
    calculateFunction,
    currencyValue,
    min,
    max,
  ) => {
    const {
      service: { floatAmount, fixedPayment },
      checkData: { vendorCurrency, currency },
    } = this.props
    return (
      <div className='service_fields-item mb-3'>
        <div>
          <label style={{ marginBottom: '5px', color: '#5e6e82' }} className='service_fields-item-title'>
            {(value !== '' || isActive) &&
              this.props.t('sum') + ', ' + currencyValue}
          </label>

          <input
            className='form-control'
            style={
              (value && Number(value) < Number(min)) ||
                (value && Number(value) > Number(max))
                ? { color: 'red' }
                : {}
            }
            onFocus={() => this.setState({ [isActiveFieldName]: true })}
            onBlur={() => this.setState({ [isActiveFieldName]: false })}
            onChange={e => {
              if (fixedPayment || this.state.fixedAmount) {
                return
              }
              if (!floatAmount) {
                const isFloatInAmount = value?.includes('.')
                if (
                  !/^\d+$/.test(e.target.value) &&
                  e.target.value &&
                  !isFloatInAmount
                ) {
                  return
                }
              }
              this.setState(calculateFunction(e.target.value))
              if (e.target.value.trim() === '') {
                this.onChange({ value: '' })
              } else {
                this.onChange(calculateFunction(e.target.value))
              }
            }}
            value={value}
            placeholder={
              isActive ? '' : this.props.t('sum') + ', ' + currencyValue
            }
          />
        </div>
        <div style={{color: "#5e6e82" }}>
          <span
            style={value && Number(value) < Number(min) ? { color: 'red' } : { color: "#5e6e82" }}
          >
            {this.props.t('Min')}
            {': '}
          </span>
          {min} {currencyValue}{' '}
          <span
            style={value && Number(value) > Number(max) ? { color: 'red' } : { color: "#5e6e82" }}
          >
            {this.props.t('Max')}
          </span>
          {': '}
          {max}
          {currencyValue || 'AZN'}
        </div>
        {vendorCurrency === currency &&
          (+this.state.vendorAmountValue).toFixed(2) !==
          (+this.state.amountValue).toFixed(2) &&
          !!Number(this.state.amountValue) &&
          !!Number(this.state.vendorAmountValue) && (
            <div style={{ marginTop: '5px' }}>
              {this.props.t('commission')}:{' '}
              {(this.state.amountValue - this.state.vendorAmountValue).toFixed(
                2,
              )}{' '}
              {vendorCurrency}
            </div>
          )}
      </div>
    )
  }

  renderForDebts() {
    return (
      <div className='service_fields-item mb-3'>
        <label className='service_fields-item-title'>
          {this.props.t('selectDebt')}
        </label>

        <select
          className='form-select max-width'
          onChange={e => {
            let item = this.state.debtsList.find(item => {
              if (item.label === e.target.value) {
                return item
              }
              return null
            })

            if (!item) {
              item = this.state.debtValue
            }
            this.pickDebt(item)
          }}
          value={this.state.debtValue}
        >
          {this.state.debtsList?.map((item, i) => {
            return (
              <option key={i} value={item.label}>
                {item.label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderTextAdditionalField(localeName, value) {
    debugger
    return (
      <div className='service_fields-item mb-3'>
        <label className='service_fields-item-title'>
          {localeName && (localeName === "debt" ? this.props.t('balance') : this.props.t(localeName))}
        </label>
        <input className={`form-control ${value < 0 ? 'red-balance' : ''}`} value={value} readOnly />
      </div>
    )
  }

  // renderDebtAdditionalField() {
  //   const { additionalDebtFields } = this.state
  //   return (
  //     <div className='service_fields-item service_debt-additional-field-items'>
  //       {additionalDebtFields.map((item, index) => {
  //         return (
  //           <div className='service_debt-additional-field-item' key={index}>
  //             <div className='service_debt-additional-field-item-title'>
  //               {item?.multiLangValueString?.[this.props.language.langKey].replace(
  //                 /<[^>]+>/g,
  //                 ' ',
  //               )}
  //             </div>
  //             <div className='service_debt-additional-field-item-body'>
  //               {item.value}
  //             </div>
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  onCheckBoxPress() {
    this.setState({ isDebtTotal: !this.state.isDebtTotal })
    if (!this.state.isDebtTotal) {
      this.setState({ amountValue: this.state.debt })
      this.onChange({ amountValue: this.state.debt })
    } else {
      this.setState({ amountValue: fixAmount(this.state.fixedAmount) })
      this.onChange({ amountValue: fixAmount(this.state.fixedAmount) })
    }
  }

  onChange(v) {
    if (!Number(v.vendorAmountValue)) {
      v.isAmountCorrect = false
    }
    if (v.vendorAmountValue !== undefined) {
      const {
        service: { minAmount, maxAmount },
      } = this.props
      v.vendorAmountValue = fixAmount(v.vendorAmountValue)
      const min = (minAmount / 100).toFixed(2)
      let max = (maxAmount / 100).toFixed(2)
      v.isAmountCorrect =
        Number(v.vendorAmountValue) >= Number(min) &&
        Number(v.vendorAmountValue) <= Number(max)
    }
    this.props.onChange(v)
  }

  calculateVendorCurrency = amount => {
    let vendorAmountValue = ''

    if (+amount) {
      vendorAmountValue = (
        Math.ceil(
          (+amount / this.props.checkData.rate - this.props.checkData.fixFee) *
          100,
        ) / 100
      ).toString()
    }

    if (isNaN(vendorAmountValue)) vendorAmountValue = ''

    return {
      amountValue: amount ? amount : '',
      vendorAmountValue,
    }
  }

  calculateCurrency = amount => {
    let amountValue = ''

    if (+amount) {
      amountValue = (
        Math.ceil(
          (this.props.checkData.fixFee + +amount) *
          this.props.checkData.rate *
          100,
        ) / 100
      ).toString()
    }

    if (isNaN(amountValue)) amountValue = ''

    return {
      vendorAmountValue: amount ? amount : '',
      amountValue,
    }
  }

  minMaxCurrency = (min, max) => {
    const minMaxCurrencyFunction = amount => {
      return amount * this.props.checkData.rate
    }

    return {
      min: (minMaxCurrencyFunction(min) / 100).toFixed(2),
      max: (minMaxCurrencyFunction(max) / 100).toFixed(2),
    }
  }

  render() {
    const {
      groupId,
      service: { id, categoryId, minAmount, maxAmount },
      checkData: { currency, vendorCurrency },
    } = this.props
    const {
      additionalFields,
      additionalDebtFields,
      debtsList,
      vendorAmountValue,
      amountValue,
      currencyIsActive,
      vendorCurrencyIsActive,
      debt,
    } = this.state
    const min = (minAmount / 100).toFixed(2)
    let max = (maxAmount / 100).toFixed(2)

    const minMaxCurrency = this.minMaxCurrency(minAmount, maxAmount)

    if (categoryId === 9 && id === 1275 && (groupId === 31 || groupId === 32)) {
      max = '500'
    }

    return (
      <div className='service_fields'>
        {additionalFields.map((item, i) => {
          return (
            <div
              key={i}
              className='service_fields-item mb-3 service_fields-item--mini-margin'
            >
              {this.renderTextAdditionalField(item?.localeName, item?.value)}
            </div>
          )
        })}

        {!!debtsList?.length && this.renderForDebts()}
        {/* {!!additionalDebtFields?.length && this.renderDebtAdditionalField()} */}
        {!debtsList?.length && debt && fixAmount(this.state.fixedAmount) && (
          <div className='service_fields-item mb-3 service_fields-item--checkbox'>
            <label htmlFor='debt-checkbox'>{this.props.t('debtTotal')}</label>
            <input
              className='form-control'
              id='debt-checkbox'
              type='checkbox'
              checked={this.state.isDebtTotal}
              onChange={() => this.onCheckBoxPress()}
            />
          </div>
        )}

        {this.amountInputRender(
          vendorAmountValue,
          vendorCurrencyIsActive,
          'vendorCurrencyIsActive',
          this.calculateCurrency,
          vendorCurrency,
          min,
          max,
        )}

        {vendorCurrency !== currency &&
          this.amountInputRender(
            amountValue,
            currencyIsActive,
            'currencyIsActive',
            this.calculateVendorCurrency,
            currency,
            minMaxCurrency?.min,
            minMaxCurrency?.max,
          )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log('111111111', state)
  const { language } = state
  const {langKey} = state;
  return {
    language,
    langKey
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AdditionalFields))
