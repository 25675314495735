import './Success.scss';
import { NavLink } from 'react-router-dom';
import userSuccessImg from '../../assets/user-success.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sima from '../../assets/sima.png';

const SimaSuccess = () => {
    const { t } = useTranslation();
    const { language } = useSelector((state: any) => state.languageReducer);

    return (
        <main className="main success" id="top">
            <div className="container">
                <div className="m-5 text-center">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img className="m-3 w-75" src={userSuccessImg} alt="successImg" />
                        </div>
                    </div>
                    <img style={{ height: '30px'}} src={sima}></img> 
                    <h3 className="text-center mt-3">
                        {language === 'az'
                            ? <><span className="text-primary"><b> PulPal</b></span>-a xoş Gəlmişsiniz!</>
                            : <><span className="text-primary">{t("Welcome")}<b> PulPal</b></span></>
                        }
                    </h3>
                    <p className="text-center">
                        <div>{t("sima")}</div>
                    </p>
                    <NavLink style={{ background: '#00d27a', borderColor: 'inherit' }} className="btn btn-success mt-5" to='/'>{t("GoToMain")}</NavLink>
                </div>
            </div>
        </main>
    )
}

export default SimaSuccess;